// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.


// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1
//
// Style guide: sass
@import 'init';

// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';

// Layouts
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';

// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 1
//
// Style guide: components

@import 'components/box/box';
@import 'components/clearfix/clearfix';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/print-none/print-none';
@import 'components/responsive-video/responsive-video';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';

// Navigation
//
// Navigation components are specialized design components that are used for
// page navigation.
//
// Weight: 2
//
// Style guide: navigation

@import 'navigation/breadcrumb/breadcrumb';
@import 'navigation/more-link/more-link';
@import 'navigation/nav-menu/nav-menu';
@import 'navigation/navbar/navbar';
@import 'navigation/pager/pager';
@import 'navigation/skip-link/skip-link';
@import 'navigation/tabs/tabs';

// Forms
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 3
//
// Style guide: forms

@import 'forms/autocomplete/autocomplete';
@import 'forms/button/button';
@import 'forms/collapsible-fieldset/collapsible-fieldset';
@import 'forms/form-item/form-item';
@import 'forms/form-table/form-table';
@import 'forms/progress-bar/progress-bar';
@import 'forms/progress-throbber/progress-throbber';
@import 'forms/resizable-textarea/resizable-textarea';
@import 'forms/table-drag/table-drag';

/*PPU Custom code*/
h1.subheading,
h3.subheading,
h5.subheading,
blockquote.subheading {
    color: color(darkblue);
}

h2.subheading,
h4.subheading {
    color: color(darkorange);
}

blockquote.subheading {
    font-weight: bold;
}

h1.subheading:before {
    background-color: color(darkblue);
    margin-bottom: 0.3em;
    display: block;
    width: 100px;
    height: 0.3rem;
    content: '';
}

h4.subheading,
h5.subheading {
   text-transform: uppercase;
}

.layout-3col {
    display: flex;
    flex-direction: column;
}


.layout-3col__left-content {
    order: 3;
    padding-bottom: 2em;
}

.layout-3col__full.layout-swap__top {
    order: 1;
    position: relative;
    height: auto;
    float: none;
}

.layout-3col__right-sidebar {
    order: 4;
}

.layout-3col #content-top {
    order: 2;
}

.layout-swap {
    padding-top: 0;
}

header {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

header .header__logo {
    float: none;
}

header nav.main-menu ul {
    display: inline-block;
}

footer .block {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

footer {
    background-color: color(footer-bg);
    color: color(white);
    font-size: font-size(xs);
}

footer ul.menu {
    padding: 0;
}

input {
    border: 1px solid color(border);
}

/*NAV*/
.nav-menu__item,
.menu__item {
    list-style: none;
}

.main-menu {
    float: none;
    margin-top: 12px;
}

.main-menu ul li a {
    color: color(black);
}

.main-menu ul li a:hover,
.main-menu ul li a.active-trail {
    color: color(grey-dark);
    text-decoration: none;
    border-bottom: 0.3rem solid color(darkorange);
}

.main-menu ul li.last {
    padding-right: 0;
}

ul.navbar li a {
    text-transform: uppercase;
}

.front h1.page-title,
.front #block-views-news-block h2.block__title {
    display: none;
}

#block-menu-menu-thematic-menu ul.menu {
    padding: 0;
    margin: 0;
    width: 100%;
}

#block-menu-menu-thematic-menu ul li a,
.field-name-field-tags a,
.more-link a,
.links-block a,
.views-field-event-type .field-content a,
input[type='submit'],
.view-events .view-header .lozenge-link a {
    color: color(thematic-mnu);
    text-transform: uppercase;
}

#block-menu-menu-thematic-menu ul li,
.more-link,
.lozenge-link {
    display: inline-block;
    text-align: center;
}

.lozenge-link {
   margin-bottom: 0.5em;
}

#block-menu-menu-thematic-menu img.thematic-image,
.responsive-menus ul.rm-removed li img.thematic-image{
    display: none;
    color: color(white);
}

.responsive-menus ul.rm-removed {
    border-bottom: 2px solid color(white);
}

.responsive-menus ul.rm-removed:nth-child(3){
    background-color: color(thematic-mnu-bg);
}

.responsive-menus.responsified .responsive-menus-simple ul.rm-removed:nth-child(3) li:hover{
    background-color: color(grey-dark);
}

#block-menu-menu-thematic-menu ul li,
.field-name-field-tags div.field-item,
.more-link,
.links-block p,
input[type='submit'],
.views-field-event-type span.field-content,
.view-events .view-header .lozenge-link {
    background-color: color(thematic-mnu-bg);
    padding: 0.5em;
    border-radius: 5px;
}

#block-menu-menu-thematic-menu ul li {
    width: 100%;
    margin-top: 0.5em;
}

#block-menu-menu-thematic-menu ul li a:hover,
.links-block p a:hover,
.more-link a:hover,
.field-name-field-tags div.field-item a:hover,
.views-field-event-type span.field-content a:hover,
.view-events .view-header .lozenge-link a:hover {
    text-decoration: none;
}

#block-menu-menu-thematic-menu ul li:hover,
#block-menu-menu-thematic-menu ul li.is-active-trail,
.links-block p:hover,
.more-link:hover,
.field-name-field-tags div.field-item:hover,
.views-field-event-type span.field-content:hover,
.view-events .view-header .lozenge-link:hover {
    background-color: color(darkorange);
}

.responsive-menus.responsified span.toggler {
    background: transparent;
    color: color(black);
    font-size: 1.5em;
    box-shadow: none;
}

.responsive-menus.responsified .responsive-menus-simple {
   box-shadow: none;
   background: color(grey-dark);
}

.responsive-menus.responsified .responsive-menus-simple li:hover {
   background: color(thematic-mnu-bg);
}

.field-name-field-tags div.field-item {
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
}

.links-block {
    text-align: center;
}


.form-donate {
    text-align: center;
}

.front #block-views-news-block {
    margin-top: 20px;
}

.view-news .field-name-title h2 a,
.news-view-block h2 a,
.view-events .views-field-title h2 a {
   color: color(black);
}

/*MASONRY*/
.masonry-item {
    margin: 0 0 20px;
    border: 0;
    padding: 0;
    width: 100%;
    background-color: color(grey-extra-light);
    box-shadow: 1px 1px 1px 1px #f5f5f5;
}

.masonry-item .field-name-title,
.masonry-item .field-name-body {
    margin: 0 0.5rem;
    padding: 0 0.5rem;
}

.masonry-item .field-name-title h2,
.masonry-item .field-name-body p {
    margin: 0.5rem 0;
}

.front .view-peace-matters .masonry-item, 
.news-view-block .masonry-item {
   width: 100%;
}

.view-peace-matters .masonry-item {
    background: transparent;
    box-shadow: none;
}

.layout-3col__right-sidebar h2.block__title {
    color: color(darkblue);
}

.layout-3col__right-sidebar h2.block__title:before {
    background-color: color(darkblue);
    margin-bottom: 0.3em;
    display: block;
    width: 100px;
    height: 0.3rem;
    content: '';
}

.layout-3col__right-sidebar .block:not(.first) {
    padding-top: 20px;
}

.field-name-body img[align='left'] {
   padding-right: 10px;
}

.field-name-body img[align='right'] {
    padding-left: 10px;
}

/*SLIDESHOW*/
.views_slideshow_main .views-field-body {
    position: relative;
    bottom: 0.5rem;
    background-color: color(text-secondary-bg-trans);
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: center;
}

.views_slideshow_main .views-field-body p {
    margin: 0.5rem;
}

.views_slideshow_main .views-field-body a {
    color: #000;
    font-size: font-size(s);
}

.views-slideshow-controls-bottom {
    text-align: center;
}

.views-slideshow-pager-fields .views-slideshow-pager-field-item.active a {
    color: #000;
}

.views-slideshow-pager-fields .active {
    text-decoration: none;
}

.views-content-counter a {
    font-size: font-size(s);
}

#content-top {
    padding-top: 10px;
}

#content-bottom,
#footer-top {
    clear: both;
}

#footer-top {
    background: color(grey-extra-light);
    padding-top: 2em;
}

.thematic-image-menu-block ul {
    margin: 0 0 1em;
    padding: 0;
}

.thematic-image-menu-block ul.menu li {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: relative;
    line-height: 1em;
}

.promo-link-block {
    position: relative;
    text-align: center;
}

.promo-link-block,
.links-block {
    max-width: 335px; /*this is size of image currently */
    margin: 0 auto;
}

.thematic-image-menu-block ul.menu li a,
.layout-3col__right-sidebar .promo-link-block h2.block__title a {
    text-transform: uppercase;
    padding: 0.5rem;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    background: color(text-bg-trans);
    color: color(white);
    font-size: font-size(m);
    margin: 0;
}

.layout-3col__right-sidebar .promo-link-block h2.block__title a {
	background: color(text-secondary-bg-trans);/*(229, 140, 82, 0.8);*/
}

.layout-3col__right-sidebar .promo-link-block h2.block__title::before {
    background-color: transparent;
    height: 0;
}

/*ARTICLE/ NEWS*/
.node-type-article h1.page-title {
    display: none;
}

/*SM SHARE*/
.node-type-page .block-addtoany,
.page-civicrm .block-addtoany {
    display: none;
}

/**EVENTS**/
.view-events .views-row {
    margin-bottom: 2.5em;
}

.view-events .views-row .views-field-title h2:before {
    background-color: color(darkorange);
    margin-bottom: 0.3em;
    display: block;
    width: 100px;
    height: 0.3rem;
    content: '';
}

.page-civicrm-event #Events_Information__1 {
    display: none;
}

.crm-container .help,
.crm-container #help {
    background-color: transparent;
    border-color: color(darkblue);
}

/*ENEWS*/
.enews-subscribe {
    text-align: center;
    margin: 2em auto 0;
    background-color: color(darkorange);
    padding: 2em 1em;
}

.enews-subscribe .form-item,
.enews-subscribe .form-actions,
.enews-subscribe .webform-civicrm-prefix,
.enews-subscribe .webform-client-form {
    display: block;
}

.enews-subscribe input[type='submit'] {
    background-color: #000;
    height: 2em;
    padding: 0 10px;
}

.enews-subscribe input[type='submit']:hover {
    background-color: #fff;
}

.enews-subscribe .webform-civicrm-prefix {
    font-size: font-size(xl);
}

.enews-subscribe .form-item {
    margin: 0.5rem 0;
}
.enews-subscribe .webform-component-textfield,
.enews-subscribe .webform-component-email {
}

/*MANUAL*/
.ui-helper-reset {
    font-size: font-size(m);
}

/*VIDEO EMBED*/
.embedded-video iframe {
    width: 98%;
}

/*PM*/
.view-peace-matters .views-field-title {
    text-align: center;
}

.view-peace-matters .views-field-title a {
    color: color(black);
    text-transform: uppercase;
}

.view-peace-matters .views-field-field-image {
    text-align: center;
}

/*SM*/
.block-social-media-links ul.platforms {
    text-align: center;
    margin: 0;
}

/*MEDIA QUERIES*/
@media only screen and (min-width: 475px) {

/*    .layout-3col .layout-3col__right-sidebar {
        flex: 1 auto;
    }

    .layout-3col .layout-3col__left-content {
        flex: 3 auto;
    }
*/
    .layout-3col {
       display: block;
    }

    .thematic-image-menu-block ul.menu li {
        width: 48%;
    }

    .thematic-image-menu-block ul.menu li:not(.last) {
         margin-right: 1%;
    }

    .masonry-item {
        width: 48%;
    }

    .not-front .view-peace-matters .masonry-item {
        width: 24%;
    }

    .view-taxonomy-term .masonry-item {
        width: 46%;
    }

    .enews-subscribe .form-item,
    .enews-subscribe .form-actions,
    .enews-subscribe .webform-civicrm-prefix,
    .enews-subscribe .webform-client-form {
        display: inline-block;
    }

    #block-menu-menu-thematic-menu ul.menu {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }

    #block-menu-menu-thematic-menu ul li {
        flex: 0 1 auto;
        margin-left: 0.5em;
	width: auto;
    }

    #block-menu-menu-thematic-menu ul li.first {
        margin-left: 0;
    }

    footer .block {
        width: 33%;
    }

    #block-menu-menu-footer-menu {
        width:32%
    }

}

@media only screen and (min-width: 600px) {
    /*SLIDESHOW*/
    .views_slideshow_main .views-field-body {
        position: absolute;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 0.5rem;
    }

}

@media only screen and (min-width: 690px) {
    #block-menu-menu-footer-menu {
        width:33%;
    }
}

@media only screen and (min-width: 999px) {
    header .header__logo {
        float: left;
    }

    header .main-menu {
        float: right;
    }

    ul.navbar li a {
       font-size: 0.9em;
    }

    .thematic-image-menu-block ul.menu li {
        width: 23.5%;
    }

    .views_slideshow_main .views-field-body a {
	font-size: font-size(xl);
    }
}

@media only screen and (min-width: 1280px) {

    ul.navbar li a {
        font-size: 1em;
    }

}
