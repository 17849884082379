// sass-lint:disable indentation, no-color-keywords

// Colors
//
// Use the `color()` function to add colors to CSS properties. To learn more,
// [read the Chroma documentation](http://johnalbin.github.io/chroma/).
//
// Markup: chroma.twig
//
// Weight: -1
//
// Style guide: sass.colors
// Define the default color scheme's color names.
$chroma: define-default-color-scheme('branding', 'The site\'s main colors. Can be used to define colors in other color schemes.');

$chroma: add-colors((
  black:                  #000,
  grey-dark:              ('black' lighten 40%), // #666
  'grey':                 ('black' lighten 60%), // #999
  grey-light:             ('black' lighten 80%), // #ccc
  grey-extra-light:       ('black' lighten 98%), // #eee
  white:                  #fff,

  blue:                   #0072b9,
  red:                    #c00,
  yellow:                 #fd0,
  midorange:              #ffb358,
  midpurple:              #9d62f4,
  midblue:                #5b8df2,
  darkorange:             #ffad4f, //#e5d652,//#e58c52,
  darkblue:               #52abe5, //#23a9f1,
));

// Define color names for functional uses.
$chroma: define-color-scheme('functional', 'Colors used by functional parts of the design.');
$chroma: add-colors('functional', (
  // Colors used in the main content area.
  text:                   'black',
  text-bg:                'white',
  text-bg-trans:          rgba(82, 171, 229, 0.95),//rgba(35, 169, 241, .8),
  text-secondary-bg-trans:rgba(255, 173, 79, 0.95),//rgba(229, 214, 82, 0.95),
  link:                   'darkblue',
  link-visited:           'darkblue',
  link-active:            'darkblue',

  border:                 'grey-light',

  autocomplete:           'text',
  autocomplete-bg:        'text-bg',
  autocomplete-select:    'white',
  autocomplete-select-bg: 'blue',

  body-bg:                'text-bg',

  button:                 'text',
  button-disabled:        'grey',

  fieldset-summary:       'grey',
  form-error:             'red',
  footer-bg:              'darkblue',
  footer-link:            'white',
  footer-text:            'white',
  mark-highlight:         'red',
  mark-bg:                'yellow',

  menu-active:            'text',
  thematic-mnu:           'white',
  thematic-mnu-bg:        'darkblue',
  preview-bg:             ('yellow' lighten 43%),

  progress-bar:           'blue',
  progress-bar-bg:        'grey-light',
  progress-bar-border:    'grey-dark',

  resizable-grippie:      'grey-extra-light',

  row-stripe:             'grey-extra-light',
  row-disabled:           'grey-light',

  skip-link:              'white',
  skip-link-bg:           'grey-dark',

  status:                 'blue',
  status-bg:              (status lighten 62%),
  status-highlight:       text,
  warning:                text,
  warning-bg:             ('yellow' lighten 45%),
  warning-border:         'yellow',
  error:                  'red',
  error-bg:               (error lighten 57%),

  tabs-border:            'border',
  tabs-bg:                'text-bg',
  tab:                    'text',
  tab-text-shadow:        'white',
  tab-bg:                 ('grey-light' lighten 6.66%),
  tab-secondary:          'grey-dark',

  table-drag:             ('yellow' lighten 43%),
  table-select:           ('yellow' lighten 47%),

  watermark:              'grey-extra-light',
));

$chroma-active-scheme: 'functional';
